import React from "react"
import Helmet from "react-helmet"

import Layout from "../layouts/es"
import snippet from "../../static/snippet.png"
import SPHeader from "../components/static-pages-layout/static-page-header"
import Spp from "../components/static-pages-layout/static-page-p"
import SPSection from "../components/static-pages-layout/static-page-section"

const AcercaDeSNIPPETPage = ({ location }) => {
  const langPaths = { en: "/about-snippet" }
  return (
    <Layout location={location} langPaths={langPaths}>
      <Helmet>
        <title>Acerca de SNIPPET</title>
        <meta
          name="description"
          content="Snippet es el token de utilidad para la plataforma social y de contenido de DevLand, DevLand Network"
        />
      </Helmet>
      <SPHeader
        superTitle="Acerca de"
        title="Snippet (SNIPPET)"
        imageLight={snippet}
        imageDark={snippet}
      >
        SNIPPET es el token que permite realizar acciones sobre la plataforma
        social y de contenido de DevLand, DevLand Network. Los usuarios deberán
        utilizar sus SNIPPET para publicar contenido (con un coste muy bajo, de
        manera simbólica para evitar el mal uso del sistema). También podrán
        publicar su contenido y cursos de pago, usando SNIPPET como moneda, así
        como contratar servicios premium sobre la plataforma.
      </SPHeader>
      <SPSection title="Información del activo">
        <Spp>
          Código del activo:{" "}
          <a
            href="https://stellar.expert/explorer/public/asset/SNIPPET-GCN2I52BQDAHGM2AYZGRJ3N4YQSDTUCHXLITKYFAFCG5DKSEVIJFK4FF"
            target="_blank"
            rel="noreferrer"
          >
            SNIPPET
          </a>
        </Spp>
        <Spp>
          Cuenta emisora:{" "}
          <a
            href="https://stellar.expert/explorer/public/account/GCN2I52BQDAHGM2AYZGRJ3N4YQSDTUCHXLITKYFAFCG5DKSEVIJFK4FF"
            target="_blank"
            rel="noreferrer"
          >
            GCN2I52BQDAHGM2AYZGRJ3N4YQSDTUCHXLITKYFAFCG5DKSEVIJFK4FF
          </a>
        </Spp>
        <Spp>Cantidad total: Ilimitada</Spp>
      </SPSection>
      <SPSection title="Tokenomics">
        <Spp>
          SNIPPET no tiene una cantidad máxima. Nuestro objetivo es que todos
          los usuarios puedan tener fácil acceso a él y que con una pequeña
          cantidad puedan utilizar la plataforma durante mucho tiempo.
        </Spp>
        <Spp>
          Inicialmente se emitieron 1 millón de SNIPPET. 800.000 fueron puestos
          a la venta a través de ofertas SNIPPET/DEVP y de{" "}
          <em>liquidity pools</em>. 200.000 quedaron reservados de manera
          aleatoria a cuentas que consideramos genuinas y gestionadas por
          humanos, tras revisión manual. Este reparto todavía continua.
        </Spp>
        <Spp>
          Cada hora, nuestros nodos revisan Horizon para comprobar cuantos{" "}
          <em>ledgers</em> ha procesado la blockchain de Stellar (~600) y emiten
          1 SNIPPET por cada <em>ledger</em>, es decir, 600 SNIPPET. Estos son
          repartidos proporcionalmente entre todos los usuarios que ya posean
          SNIPPET.
        </Spp>
        <Spp>
          Con esto queremos que los usuarios puedan adquirir una pequeña
          cantidad que les genere recompensas suficientes para mantener un uso
          moderado pero constante de la aplicación que les permita publicar.
        </Spp>
        <Spp>
          Para consumir contenido gratuito publicado por usuarios (lectura) no
          será necesario gastar SNIPPET.
        </Spp>
      </SPSection>
      <SPSection title="Aviso">
        <Spp>
          SNIPPET no es un producto de inversión. No hay activos reales que
          respalden su valor.
        </Spp>
        <Spp>
          Sus funciones específicas son la de actuar como cuota de uso sobre los
          recursos de DevLand Network que estén centralizados y tengan un coste,
          y la de servir como medio de pago por contenido para los usuarios
          dentro de la plataforma.
        </Spp>
        <Spp>
          Por ello, no permitiremos que un mal uso del token, como la
          acaparación con interés especulativo, si obstaculiza su uso legítimo.
          De manera consensuada, votando mediante <strong>DEVP</strong>, la
          organización descentralizada se reserva el derecho de emitir o quemar
          la cantidad de token que sea necesaria en cualquier momento con el fin
          de mitigar estas prácticas abusivas.
        </Spp>
      </SPSection>
    </Layout>
  )
}
export default AcercaDeSNIPPETPage
