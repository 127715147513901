import React, { useContext } from "react"
import ThemeContext from "../../context/theme-context"

const StaticPageHeader = ({
  children,
  title,
  superTitle,
  imageLight,
  imageDark,
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <section className="flex flex-col mt-12 mb-8 md:flex-row">
      <div className="flex-1">
        <div>{superTitle}</div>
        <h1 className="mt-2">{title}</h1>
        <p className="mt-4 leading-relaxed">{children}</p>
      </div>
      <div className="flex-1">
        <img
          className="w-60 m-auto my-12 md:my-auto"
          src={theme !== "dark" ? imageLight : imageDark}
        />
      </div>
    </section>
  )
}

export default StaticPageHeader
