import React from "react"

const StaticPageSection = ({ title, children }) => (
  <section className="mt-12 mb-8 md:max-w-4xl">
    <h2>{title}</h2>
    {children}
  </section>
)

export default StaticPageSection
